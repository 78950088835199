import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { loadHTML } from '../class/loadHTML';
import Navbar from '../components/navbar';
import Footer from '../components/Footer';
import { Modal, ModalProvider } from '../components/ModalComponents';

function BlogPost() {
  const { slug } = useParams();
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      const htmlContent = await loadHTML(slug);
      setContent(htmlContent);
    };
    fetchContent();
  }, [slug]);

  return (
    <ModalProvider>
      <Navbar />
      <div className="max-w-4xl mx-auto p-4 mt-20">
        <div
          className="prose lg:prose-xl"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <Footer />
    </ModalProvider>
  );
}

export default BlogPost;
