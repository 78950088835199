import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SelectTranslation = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
    setSelectedLanguage(language);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    const browserLanguage = navigator.language.slice(0, 2); // Get the first two letters of the browser language

    const supportedLanguages = ['en', 'de', 'cs']; // Add more languages here if needed

    // Set the language based on saved language, browser language, or default to English
    const initialLanguage = savedLanguage || (supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en');

    i18n.changeLanguage(initialLanguage);
    setSelectedLanguage(initialLanguage);
  }, [i18n]);

  return (
    <div className="flex space-x-2">
      <button
        onClick={() => changeLanguage('en')}
        className={`${
          selectedLanguage === 'en' ? 'text-white font-bold' : 'hover:text-white'
        }`}
      >
        EN
      </button>
      <button
        onClick={() => changeLanguage('de')}
        className={`${
          selectedLanguage === 'de' ? 'text-white font-bold' : 'hover:text-white'
        }`}
      >
        DE
      </button>
      <button
        onClick={() => changeLanguage('cs')}
        className={`${
          selectedLanguage === 'cs' ? 'text-white font-bold' : 'hover:text-white'
        }`}
      >
        CS
      </button>
    </div>
  );
};

export default SelectTranslation;
