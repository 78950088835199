import React from 'react';
import { useTranslation } from 'react-i18next';
import CtaButton from "./CtaButton";
import Question from "../assets/img/question.svg";
import Fact from "../assets/img/factcheck.svg";
import Event from "../assets/img/event_green.svg";
import Robi from "../assets/img/robert_kolar_square.jpg";

export default function MainContent() {
  const { t } = useTranslation();

  return (
    <div className="relative isolate overflow-hidden bg-white py-24 sm:py-32 mt-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <p className="text-lg font-semibold leading-8 tracking-tight text-emerald-600">
            {t('mainContent.service')}
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t('mainContent.heading')}
          </h1>
          <p className="mt-6 text-xl leading-8 text-gray-700">
            {t('mainContent.introText')}
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
          <div className="relative lg:order-last lg:col-span-5">
            <figure className="border-l border-emerald-600 pl-8">
              <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                <p>
                  {t('mainContent.quote.text')}
                </p>
              </blockquote>
              <figcaption className="mt-8 flex gap-x-4">
                <img
                  alt={t('mainContent.quote.author')}
                  src={Robi}
                  className="mt-1 h-10 flex-none rounded-full bg-gray-50"
                />
                <div className="text-sm leading-6">
                  <div className="font-semibold text-gray-900">
                    {t('mainContent.quote.author')}
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
            <p>
              {t('mainContent.content.paragraph1')}
            </p>

            <div className="mt-8 max-w-xl space-y-8 text-gray-600">
              <div className="flex gap-x-3">
                <img src={Question} alt={t('mainContent.content.list[0].title')} aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                <div>
                  <strong className="font-semibold text-gray-900">
                    {t('mainContent.content.list.0.title')}
                  </strong>{" "}
                  {t('mainContent.content.list.0.description')}
                </div>
              </div>

              <div className="flex gap-x-3">
                <img src={Event} alt={t('mainContent.content.list.1.title')} aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                <div>
                  <strong className="font-semibold text-gray-900">
                    {t('mainContent.content.list.1.title')}
                  </strong>{" "}
                  {t('mainContent.content.list.1.description')}
                </div>
              </div>

              <div className="flex gap-x-3">
                <img src={Fact} alt={t('mainContent.content.list.2.title')} aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                <div>
                  <strong className="font-semibold text-gray-900">
                    {t('mainContent.content.list.2.title')}
                  </strong>{" "}
                  {t('mainContent.content.list.2.description')}
                </div>
              </div>
            </div>

            <div className="mt-5">
              <CtaButton text={t('mainContent.ctaButton')} url="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
