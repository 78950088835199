import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/img/logo.png";
import SelectTranslation from "./SelectTranslation";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto py-20 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className="text-left mb-8 md:mb-0">
            <img src={logo} alt="Lang & Partner" className="h-8 mb-4" />
            <SelectTranslation />
          </div>
          <div className="text-left flex flex-col md:flex-row md:space-x-24">
            <div className="mb-8 md:mb-0">
              <h3 className="text-xl font-bold">
                {t("footer.quickLinksHeading")}
              </h3>
              <ul className="mt-4 space-y-2">
                <li>
                  <a
                    href="/"
                    className="hover:text-emerald-400 transition-colors duration-200"
                  >
                    {t("footer.home")}
                  </a>
                </li>
                <li>
                  <a
                    href="/insurance"
                    className="hover:text-emerald-400 transition-colors duration-200"
                  >
                    {t("footer.insurance")}
                  </a>
                </li>
                <li>
                  <a
                    href="/mandate"
                    className="hover:text-emerald-400 transition-colors duration-200"
                  >
                    {t("footer.mandate")}
                  </a>
                </li>
                <li>
                  <a
                    href="/newsroom"
                    className="hover:text-emerald-400 transition-colors duration-200"
                  >
                    {t("footer.newsroom")}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold">
                {t("footer.contactHeading")}
              </h3>
              <p className="mt-4">Lang + Partner | Finanzdienstleistungen GmbH</p>
              <p className="mt-2">Chilegässli 12a, 8904 Aesch ZH</p>
              <p className="mt-2">
                <a
                  href="mailto:info@langpartner.ch"
                  className="hover:text-emerald-400 transition-colors duration-200"
                >
                  info@langpartner.ch
                </a>
              </p>
              <p className="mt-2">
                <a
                  href="tel:+41763648888"
                  className="hover:text-emerald-400 transition-colors duration-200"
                >
                  +41 76 364 88 88
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-6">
          <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
            <div className="text-left">
              <p className="text-sm text-gray-600 transition-colors duration-200">
                {t("footer.copyRight")}
              </p>
              <a href="https://www.webermarc.com">
                <p className="text-sm text-gray-600 hover:text-gray-400 transition-colors duration-200 cursor-pointer">
                  {t("footer.developedBy")}
                </p>
              </a>
            </div>
            <div className="flex space-x-6 mt-4 md:mt-0 text-center md:text-left">
              <a href="/privacy">
                <p className="text-sm text-gray-600 hover:text-gray-400 transition-colors duration-200 cursor-pointer">
                  {t("footer.privacyPolicy")}
                </p>
              </a>
              <a href="/tos">
                <p className="text-sm text-gray-600 hover:text-gray-400 transition-colors duration-200 cursor-pointer">
                  {t("footer.termsOfService")}
                </p>
              </a>
              <a href="/sitemap.xml">
                <p className="text-sm text-gray-600 hover:text-gray-400 transition-colors duration-200 cursor-pointer">
                  {t("footer.sitemap")}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
