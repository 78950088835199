import React from 'react';
import { useTranslation } from 'react-i18next';
import Robi from '../assets/img/robert_kolar_square.jpg'; // Ensure this path is correct
import Family from '../assets/img/family.jpg'; // Ensure this path is correct

const posts = [
  {
    id: 1,
    titleKey: 'blogList.post.title',
    href: '/post/praemie-krankenkasse',
    descriptionKey: 'blogList.post.description',
    src: Family,
    dateKey: 'blogList.post.date',
    datetime: '2024-08-16',
    category: { titleKey: 'blogList.post.category', href: '#' },
    author: {
      nameKey: 'blogList.post.author.name',
      roleKey: 'blogList.post.author.role',
      href: '#',
      src: Robi,
    },
  },
  // Add more posts here as needed
];

export default function BlogList() {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('blogList.heading')}</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            {t('blogList.description')}
          </p>
          <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
            {posts.map((post) => (
              <article key={post.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
                <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                  <img
                    alt={t(post.titleKey)} // Added alt text for accessibility
                    src={post.src}
                    className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div>
                  <div className="flex items-center gap-x-4 text-xs">
                    <time dateTime={post.datetime} className="text-gray-500">
                      {t(post.dateKey)}
                    </time>
                    <div
                      
                      className="relative z-10 rounded-full bg-emerald-100 px-3 py-1.5 font-medium text-gray-600 "
                    >
                      {t(post.category.titleKey)}
                    </div>
                  </div>
                  <div className="group relative max-w-xl">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a href={post.href}>
                        <span className="absolute inset-0" />
                        {t(post.titleKey)}
                      </a>
                    </h3>
                    <p className="mt-5 text-sm leading-6 text-gray-600">{t(post.descriptionKey)}</p>
                  </div>
                  <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                    <div className="relative flex items-center gap-x-4">
                      <img alt={t(post.author.nameKey)} src={post.author.src} className="h-10 w-10 rounded-full bg-gray-50" />
                      <div className="text-sm leading-6">
                        <p className="font-semibold text-gray-900">

                            <span className="absolute inset-0" />
                            {t(post.author.nameKey)}

                        </p>
                        <p className="text-gray-600">{t(post.author.roleKey)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
