export default function Card({ img, title, url }) {
    return (
        <a href={url} className="hover:text-emerald-500">
        <div className="overflow-hidden rounded-lg bg-white shadow-lg transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-2xl hover:bg-gray-100">
            <div className="px-4 py-5 sm:p-6">
                <img src={img} alt={title} className="mx-auto h-48 object-cover transition duration-300 ease-in-out" />
            </div>
            <div className="px-4 py-4 sm:px-6 text-lg font-semibold text-gray-800 text-center">
                {title}
            </div>
        </div>
        </a>

    )
}
