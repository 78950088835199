import React from 'react';
import { useTranslation } from 'react-i18next';
import CtaButton from "./CtaButton";

export default function OnlineCTA() {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center">
      <div className="bg-white p-6 border border-gray-200 rounded-lg shadow-sm p-4 text-center max-w-sm">
        <p className="mb-4">{t('onlineCTA.description')}</p>
        <CtaButton text={t('onlineCTA.ctaButton')} url="#" />
      </div>
    </div>
  );
}
