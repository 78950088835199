import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import l5 from "../assets/img/l5.webp";
import l4 from "../assets/img/l4.webp";
import l3 from "../assets/img/l3.webp";
import l2 from "../assets/img/l2.webp";
import l1 from "../assets/img/l1.webp";
import CtaButton from "./CtaButton";

export default function Hero() {
  const { t } = useTranslation();
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const aspectRatio = 1280 / 850;

    const updateSize = () => {
      const width = window.innerWidth;
      const height = width / aspectRatio;
      container.style.width = `${width}px`;
      container.style.height = `${height}px`;
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    const parallax_elements = document.querySelectorAll(".parallax");

    let xValue = 0,
      yValue = 0;
    let isMoving = false;

    const handleMouseMove = (e) => {
      xValue = e.clientX - window.innerWidth / 2;
      yValue = e.clientY - window.innerHeight / 2;

      if (!isMoving) {
        requestAnimationFrame(updateParallax);
        isMoving = true;
      }
    };

    const updateParallax = () => {
      parallax_elements.forEach((element) => {
        let intensityX = element.getAttribute("data-intensityx");
        let intensityY = element.getAttribute("data-intensityy");

        let translateX = -50 + -1 * xValue * intensityX;
        let translateY = -50 + yValue * intensityY;

        if (element.classList.contains("l4") && xValue > 666) {
          translateX = -50 + -1 * 666 * intensityX;
        } else if (element.classList.contains("l3") && xValue < -1000) {
          translateX = -50 + -1 * -1000 * intensityX;
        } else if (xValue < -1000) {
          translateX = -50 + -1 * -1000 * intensityX;
        } else if (xValue > 1000) {
          translateX = -50 + -1 * 1000 * intensityX;
        } else if (element.classList.contains("l1") && yValue < 0) {
          translateY = -50 + 20 * intensityY;
        }

        element.style.transform = `translate(${translateX}%, ${translateY}%) 
                translateZ(200px)`;
      });
      isMoving = false;
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="hero-section bg-green-700 overflow-hidden w-screen h-screen relative perspective-container"
      style={{ maxWidth: 2600, maxHeight: 1260 }}
    >
      <img
        src={l5}
        className="parallax l5 absolute z-0"
        data-intensityx="0"
        data-intensityy="0"
        data-intensityz=""
      />
      <img
        src={l4}
        className="parallax l4 absolute z-10"
        data-intensityx="0.0035"
        data-intensityy="0.0075"
        data-intensityz=""
      />
      <img
        src={l3}
        className="parallax l3 absolute z-20"
        data-intensityx="0.003"
        data-intensityy="0.005"
        data-intensityz=""
      />
      <img
        src={l2}
        className="parallax l2 absolute z-30"
        data-intensityx="0.002"
        data-intensityy="0.0025"
        data-intensityz=""
      />
      <img
        src={l1}
        className="parallax l1 absolute z-40"
        data-intensityx="0.0015"
        data-intensityy="0.001"
        data-intensityz=""
      />
      <div
        className="relative isolate overflow-hidden flex justify-center items-center h-full"
        style={{ zIndex: 9000 }}
      >
        <div className="mx-auto max-w-2xl lg:max-w-xl flex-shrink-0 backdrop-blur-md px-5 py-12 rounded-lg overflow-hidden">
          <h1 className="text-3xl sm:text-4xl lg:text-6xl font-bold tracking-tight text-white text-center">
            {t('hero.heading')}
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-300 text-center">
            {t('hero.subheading')}
          </p>
          <div className="mt-10 flex flex-col sm:flex-row justify-center items-center gap-x-6">
            <CtaButton text={t('hero.ctaButtonText')} url="" />
            <a
              href="/insurance"
              className="mt-4 sm:mt-0 text-sm font-semibold leading-6 text-white"
            >
              {t('hero.learnMoreText')} <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
      <div className="custom-shape-divider-bottom-1722819675">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
}
