import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Input from "./Input";
import { MdApartment } from "react-icons/md";
import { FaPerson } from "react-icons/fa6";
import Feature from "./Feature";
import Family from "../assets/img/family-forest.jpg";
import OnlineCTA from "./OnlineCTA";

export default function FormMandate({ onNext }) {
  const { t } = useTranslation();

  const [selectedType, setSelectedType] = useState("IN");
  const [firmenname, setFirmenname] = useState("");
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [adresse, setAdresse] = useState("");
  const [ort, setOrt] = useState("");
  const [plz, setPlz] = useState("");
  const [email, setEmail] = useState("");
  const [lang, setLang] = useState("de");

  useEffect(() => {
    try {
      const savedLang = localStorage.getItem('selectedLanguage');
      if (savedLang) {
        setLang(savedLang);
      }
    } catch (error) {
      console.error('Error setting language:', error);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    if (
      (selectedType === "KMU" && !firmenname) ||
      !givenName ||
      !familyName ||
      !adresse ||
      !ort ||
      !plz ||
      !email
    ) {
      alert(t('formMandate.errorMessage')); // Show alert with the error message
      return; // Prevent form submission
    }

    const formData = {
      type: selectedType,
      firmenname,
      givenName,
      familyName,
      adresse,
      ort,
      plz,
      email,
      lang
    };
    console.log("Form Data:", formData); // Log form data to ensure it's being collected
    onNext(formData); // Pass the data to the onNext function
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="text-black mt-10 max-w-2xl mx-auto border border-gray-200 rounded-lg shadow-sm p-4">
          {/* Type Selection */}
          <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-6 mt-3 w-full">
            <label className="flex flex-col items-center cursor-pointer w-full">
              <input
                className="hidden"
                type="radio"
                name="type"
                value="IN"
                checked={selectedType === "IN"}
                onChange={handleTypeChange}
              />
              <span
                className={`block w-full text-center font-medium text-lg ${
                  selectedType === "IN"
                    ? "bg-emerald-600 text-white"
                    : "bg-white text-black border-2 border-black"
                } rounded-lg p-4`}
              >
                <FaPerson className="text-4xl mx-auto mb-2" />
                {t('formMandate.typeSelection.individual')}
              </span>
            </label>
            <label className="flex flex-col items-center cursor-pointer w-full">
              <input
                className="hidden"
                type="radio"
                name="type"
                value="KMU"
                checked={selectedType === "KMU"}
                onChange={handleTypeChange}
              />
              <span
                className={`block w-full text-center font-medium text-lg ${
                  selectedType === "KMU"
                    ? "bg-emerald-600 text-white"
                    : "bg-white text-black border-2 border-black"
                } rounded-lg p-4`}
              >
                <MdApartment className="text-4xl mx-auto mb-2" />
                {t('formMandate.typeSelection.company')}
              </span>
            </label>
          </div>

          {/* Firmenname (only visible when Firmenkunde is selected) */}
          {selectedType === "KMU" && (
            <div className="mt-6">
              <Input
                id="firmenname"
                name="firmenname"
                type="text"
                label={t('formMandate.labels.companyName')}
                placeholder={t('formMandate.labels.companyName')}
                value={firmenname}
                onChange={(e) => setFirmenname(e.target.value)}
                required
              />
            </div>
          )}

          {/* Personalien */}
          <span className="text-xl text-gray-800 leading-tight mt-8 block">
            {t('formMandate.labels.personalInfo')}
          </span>
          <div className="flex space-x-6 w-full mt-6">
            <div className="flex-1">
              <Input
                id="given_name"
                name="given_name"
                type="text"
                label={t('formMandate.labels.firstName')}
                placeholder="Max"
                value={givenName}
                onChange={(e) => setGivenName(e.target.value)}
                required
              />
            </div>
            <div className="flex-1">
              <Input
                id="family_name"
                name="family_name"
                type="text"
                label={t('formMandate.labels.lastName')}
                placeholder="Mustermann"
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
                required
              />
            </div>
          </div>

          {/* Adresse */}
          <span className="text-xl text-gray-800 leading-tight mt-8 block">
            {t('formMandate.labels.addressInfo')}
          </span>
          <div className="mt-6">
            <Input
              id="adresse"
              name="adresse"
              type="text"
              label={t('formMandate.labels.address')}
              placeholder="Bahnhofstrasse 1"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
              required
            />
          </div>
          <div className="flex space-x-6 w-full mt-6">
            <div className="flex-1">
              <Input
                id="ort"
                name="ort"
                type="text"
                label={t('formMandate.labels.city')}
                placeholder="Zürich"
                value={ort}
                onChange={(e) => setOrt(e.target.value)}
                required
              />
            </div>
            <div className="flex-1">
              <Input
                id="plz"
                name="plz"
                type="number"
                label={t('formMandate.labels.postalCode')}
                placeholder="8001"
                value={plz}
                onChange={(e) => setPlz(e.target.value)}
                required
              />
            </div>
          </div>

          {/* Kontakt */}
          <span className="text-xl text-gray-800 leading-tight mt-8 block">
            {t('formMandate.labels.contactInfo')}
          </span>
          <div className="mt-6">
            <Input
              id="email"
              name="email"
              type="email"
              label={t('formMandate.labels.email')}
              placeholder="max@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {/* Submit Button */}
          <div className="mt-10 flex justify-center">
            <button
              type="submit"
              className="bg-emerald-600 text-white font-bold py-2 px-4 rounded-md hover:bg-emerald-700 transition-colors"
            >
              {t('formMandate.submitButton')}
            </button>
          </div>
        </div>
      </form>
      <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl max-w-2xl px-6 sm:px-0 mx-auto mt-20">
        {t('formMandate.sectionTitle')}
      </h1>
      <div className="bg-white mb-12 mt-8">
        <div className="mx-auto max-w-2xl px-6 sm:px-0">
          <p className="text-base leading-7 text-gray-600">
            {t('formMandate.description')}
          </p>
          <img
            src={Family}
            alt="Insurance"
            className="mt-8 w-full h-64 object-cover rounded-lg"
          />
        </div>
      </div>

      <Feature />
      <div className="mb-12">
        <OnlineCTA />
      </div>
    </>
  );
}
