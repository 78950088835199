import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import Allianz from "../assets/img/insurances/allianz.png";
import Automate from "../assets/img/insurances/automate.png";
import AXA from "../assets/img/insurances/axa.png";
import Baloise from "../assets/img/insurances/baloise.png";
import Concordia from "../assets/img/insurances/concordia.png";
import Coop from "../assets/img/insurances/coop.png";
import CSS from "../assets/img/insurances/css.png";
import Dextra from "../assets/img/insurances/dextra.png";
import DieMobiliar from "../assets/img/insurances/diemobiliar.png";
import ElipsLife from "../assets/img/insurances/elipslife.png";
import Orion from "../assets/img/insurances/Orion.png";
import ERV from "../assets/img/insurances/erv.png";
import Generali from "../assets/img/insurances/generali.png";
import GroupeMutuel from "../assets/img/insurances/groupemutuel.png";
import Helsana from "../assets/img/insurances/helsana.png";
import Helvetia from "../assets/img/insurances/helvetia.png";
import Innova from "../assets/img/insurances/innova.png";
import LichtensteinLife from "../assets/img/insurances/lichtensteinlife.png";
import OEKK from "../assets/img/insurances/OEKK.png";
import PAX from "../assets/img/insurances/pax.png";
import Protekta from "../assets/img/insurances/protekta.png";
import Sanitas from "../assets/img/insurances/sanitas.png";
import Smile from "../assets/img/insurances/smile.png";
import Solida from "../assets/img/insurances/solida.png";
import Swica from "../assets/img/insurances/swica.png";
import SwissLife from "../assets/img/insurances/swisslife.png";
import Sympany from "../assets/img/insurances/sympany.png";
import Vaudoise from "../assets/img/insurances/vaudoise.png";
import Visana from "../assets/img/insurances/visana.png";
import Zurich from "../assets/img/insurances/zurich.png";

export default function InsuranceSelection({ onNext }) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [selectedInsurances, setSelectedInsurances] = useState([]);
  const containerRef = useRef(null);

  const insurances = [
    { name: "Allianz", img: Allianz },
    { name: "Automate", img: Automate },
    { name: "AXA", img: AXA },
    { name: "Baloise", img: Baloise },
    { name: "Concordia", img: Concordia },
    { name: "Coop", img: Coop },
    { name: "CSS", img: CSS },
    { name: "Dextra", img: Dextra },
    { name: "Die Mobiliar", img: DieMobiliar },
    { name: "ElipsLife", img: ElipsLife },
    { name: "Orion", img: Orion },
    { name: "Europäische Reiseversicherungs AG", img: ERV },
    { name: "Generali", img: Generali },
    { name: "Groupe Mutuel", img: GroupeMutuel },
    { name: "Helsana", img: Helsana },
    { name: "Helvetia", img: Helvetia },
    { name: "Innova", img: Innova },
    { name: "LichtensteinLife", img: LichtensteinLife },
    { name: "ÖKK", img: OEKK },
    { name: "PAX", img: PAX },
    { name: "Protekta", img: Protekta },
    { name: "Sanitas", img: Sanitas },
    { name: "Smile", img: Smile },
    { name: "Solida", img: Solida },
    { name: "Swica", img: Swica },
    { name: "Swiss Life", img: SwissLife },
    { name: "Sympany", img: Sympany },
    { name: "Vaudoise", img: Vaudoise },
    { name: "Visana", img: Visana },
    { name: "Zurich", img: Zurich },
  ];

  useEffect(() => {
    const setCardHeights = () => {
      const cards = containerRef.current.querySelectorAll(".card");
      cards.forEach((card) => {
        card.style.height = `${card.clientWidth}px`;
      });
    };

    setCardHeights();
    window.addEventListener("resize", setCardHeights);
    return () => {
      window.removeEventListener("resize", setCardHeights);
    };
  }, []);

  const toggleSelection = (insuranceName) => {
    setSelectedInsurances((prevSelected) =>
      prevSelected.includes(insuranceName)
        ? prevSelected.filter((name) => name !== insuranceName)
        : [...prevSelected, insuranceName]
    );
  };

  const filteredInsurances = insurances.filter((insurance) =>
    insurance.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(selectedInsurances);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container mx-auto p-4 max-w-2xl">
        <h2 className="text-3xl font-bold mb-4">{t('insuranceSelection.heading')}</h2>
        <input
          type="text"
          id="searchBar"
          className="mb-4 p-2 border border-gray-300 rounded w-full"
          placeholder={t('insuranceSelection.searchPlaceholder')}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div
          name="versicherungenArray"
          id="versicherungenArray"
          className="grid gap-4 grid-cols-2 sm:grid-cols-3 lg:grid-cols-5"
          ref={containerRef}
        >
          {filteredInsurances.map((insurance) => (
            <div
              key={insurance.name}
              className={`card relative overflow-hidden bg-white shadow sm:rounded-lg ${
                selectedInsurances.includes(insurance.name) ? "selected" : ""
              }`}
              onClick={() => toggleSelection(insurance.name)}
            >
              <div className="flex items-center justify-center h-full px-4 py-5 sm:p-6">
                <img
                  src={insurance.img}
                  alt={insurance.name}
                  className="max-w-full max-h-full"
                />
                {selectedInsurances.includes(insurance.name) && (
                  <FaCheckCircle
                    className="absolute top-2 right-2 text-emerald-500"
                    size={24}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-10 flex justify-center">
          <button
            type="submit"
            className="bg-emerald-600 text-white font-bold py-2 px-4 rounded-md hover:bg-emerald-700 transition-colors"
          >
            {t('insuranceSelection.submitButtonText')}
          </button>
        </div>
      </div>
    </form>
  );
}
