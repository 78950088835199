import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "./Input";
import Textarea from "./Textarea";

export default function Form() {
  const { t } = useTranslation();
  const [selectedInsurances, setSelectedInsurances] = useState([]);

  const handleInsuranceToggle = (insuranceType) => {
    setSelectedInsurances((prevSelected) =>
      prevSelected.includes(insuranceType)
        ? prevSelected.filter((type) => type !== insuranceType)
        : [...prevSelected, insuranceType]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get form values
    const name = document.getElementById('name').value.trim();
    const surname = document.getElementById('surname').value.trim();
    const email = document.getElementById('email').value.trim();
    const phone = document.getElementById('phone').value.trim();
    const message = document.getElementById('message').value.trim();

    // Check if all required fields are filled
    if (!name || !surname || !email || !phone || !message) {
      alert(t('form.validationMessage')); // Show validation message
      return;
    }

    const data = {
      name,
      surname,
      email,
      phone,
      message,
      selectedInsurances,
    };

    try {
      const response = await fetch('https://wwtivedhh5.execute-api.eu-central-1.amazonaws.com/prd/contact', {
        method: 'POST',
        body: JSON.stringify(data),
      });

      if (response.ok) {
        alert(t('form.successMessage'));
      } else {
        alert(t('form.failureMessage'));
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(t('form.failureMessage'));
    }
  };

  return (
    <div className="text-black py-10 max-w-xl mx-auto sticky top-20">
      <h2 className="text-3xl font-bold mb-4">{t('form.heading')}</h2>

      <form
        onSubmit={handleSubmit}
        className="space-y-6 border border-gray-200 rounded-lg shadow-sm p-4"
      >
        <div className="flex space-x-6 w-full">
          <div className="flex-1">
            <Input id="name" name="Vorname" label={t('form.labels.firstName')} type="text" placeholder="Max" required />
          </div>
          <div className="flex-1">
            <Input
              id="surname"
              name="Nachname"
              label={t('form.labels.lastName')}
              type="text"
              placeholder="Mustermann"
              required
            />
          </div>
        </div>

        <Input
          id="email"
          name="Email"
          label={t('form.labels.email')}
          type="email"
          placeholder="max@gmail.com"
          required
        />
        <Input id="phone" name="Telefon" label={t('form.labels.phone')} type="tel" placeholder="0790000000" required />
        <Textarea
          id="message"
          name="Nachricht"
          label={t('form.labels.message')}
          placeholder="Krankenkasse Offerte..."
          required
        />

        <div className="mt-6">
          <p className="mb-2 font-medium">{t('form.insuranceTypesLabel')}</p>
          <div className="flex flex-wrap gap-4">
            {[
              t('form.insuranceTypes.health'),
              t('form.insuranceTypes.vehicle'),
              t('form.insuranceTypes.life'),
              t('form.insuranceTypes.household'),
              t('form.insuranceTypes.other'),
            ].map((type) => (
              <button
                type="button"
                key={type}
                onClick={() => handleInsuranceToggle(type)}
                className={`${
                  selectedInsurances.includes(type)
                    ? "bg-emerald-600 text-white"
                    : "bg-white text-black border-2 border-black"
                } px-4 py-2 rounded-md`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-8 flex justify-center">
          <button
            type="submit"
            className="bg-emerald-600 text-white font-bold py-2 px-4 rounded-md hover:bg-emerald-700 transition-colors"
          >
            {t('form.submitButton')}
          </button>
        </div>
      </form>
    </div>
  );
}
