import React from 'react';
import eventImg from '../assets/img/calculator-solid.svg';
import { Modal, ModalTrigger, ModalBody, ModalContent, ModalFooter, useModal } from './ModalComponents';

export default function CtaButton({ text }) {
    const { setOpen } = useModal();
  return (
    <Modal>
      <ModalTrigger className="inline-flex h-12 items-center justify-center rounded-md border border-slate-800 bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] px-6 font-medium text-white transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 button-container animate-shimmer">
        <span className="button-text">{text}</span>
        <img src={eventImg} alt="Event" className="h-6 button-icon" />
      </ModalTrigger>

      <ModalBody>
        <ModalContent>
          <h2 className="text-2xl font-bold">Modal Title</h2>
          <p className="mt-4">Your modal content goes here.</p>
        </ModalContent>
        <ModalFooter>
          <button onClick={() => setOpen(false)} className="px-4 py-2 bg-gray-200 rounded-md">
            Close
          </button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}
