import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from "./Card";
import Care from "../assets/img/care.svg";
import Shield from "../assets/img/shield.svg";
import Health from "../assets/img/health.svg";

export default function Products() {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-3xl font-bold text-center mt-32">{t('products.heading')}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 mt-20 drop-shadow-lg max-w-7xl mx-auto">
        <Card img={Health} title={t('products.healthInsurance')} url="/insurance" />
        <Card img={Care} title={t('products.mandate')} url="/mandate" />
        <Card img={Shield} title={t('products.insurance')} url="/insurance" />
      </div>
    </>
  );
}
