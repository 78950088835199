import React from 'react';
import { useTranslation } from 'react-i18next';
import Robi from '../assets/img/robert_kolar.jpg';

const people = [
  {
    name: 'Robert Kolar',
    role: 'Versicherungsberater',
    linkedinUrl: 'https://www.linkedin.com/in/robert-kolar-9bb889152/',
  },
];

export default function Team() {
  const { t } = useTranslation();

  return (
    <div className="bg-white pt-24 sm:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t('team.heading')}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('team.description')}
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-[400px] "
        >
          {people.map((person) => (
            <li key={person.name}>
              <img alt={person.name} src={Robi} className="aspect-[3/2] w-full rounded-2xl object-cover" />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {t(`team.people.${person.name}.name`)}
              </h3>
              <p className="text-base leading-7 text-gray-600">
                {t(`team.people.${person.name}.role`)}
              </p>
              <ul role="list" className="mt-6 flex gap-x-6">
                <li>
                  <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">LinkedIn</span>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5"
                    >
                      <path
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
