import React, { useEffect } from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from './pages/Home';
import Insurance from './pages/Insurance';
import Mandate from './pages/Mandate';
import Newsroom from './pages/Newsroom';
import BlogPost from './pages/Blogpost';
import TOS from './pages/TOS';
import Privacy from './pages/Privacy';

function App() {
  useEffect(() => {
    const currentHost = window.location.host;

    if (currentHost === 'lang-partner.ch' || currentHost === 'www.lang-partner.ch') {
      window.location.replace('https://langpartner.ch');
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/mandate" element={<Mandate />} />
        <Route path="/newsroom" element={<Newsroom />} />
        <Route path="/post/:slug" element={<BlogPost />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/sitemap.xml" />
      </Routes>
    </Router>

  );
}

export default App;
