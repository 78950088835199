import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Mandat from "../assets/file/mandat_robert_kolar.pdf";

export default function SignatureSection({ onNext }) {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const saveBtnRef = useRef(null);
  const [hasDrawn, setHasDrawn] = useState(false);
  const isDrawing = useRef(false);

  // Global clearCanvas function
  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setHasDrawn(false);
    saveBtnRef.current.disabled = true;
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    onNext(dataURL); // Pass the signature data to the onNext function
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const saveBtn = saveBtnRef.current;

    const isMobileDevice = () => {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
      );
    };

    const dpr = isMobileDevice() ? window.devicePixelRatio || 1 : 1;
    canvas.width = canvas.offsetWidth * dpr;
    canvas.height = canvas.offsetHeight * dpr;
    ctx.scale(dpr, dpr);

    const startDrawing = (e) => {
      isDrawing.current = true;
      ctx.beginPath();
      draw(e);
    };

    const stopDrawing = () => {
      isDrawing.current = false;
      ctx.closePath(); // Ensure the path is closed properly after drawing stops
    };

    const draw = (e) => {
      if (!isDrawing.current) return;

      let x, y;

      if (e.touches) {
        x = e.touches[0].clientX - canvas.getBoundingClientRect().left;
        y = e.touches[0].clientY - canvas.getBoundingClientRect().top;
      } else {
        x = e.clientX - canvas.getBoundingClientRect().left;
        y = e.clientY - canvas.getBoundingClientRect().top;
      }

      ctx.lineWidth = 2;
      ctx.lineCap = "round";
      ctx.strokeStyle = "black";

      ctx.lineTo(x, y);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(x, y);

      if (!hasDrawn) {
        setHasDrawn(true);
        saveBtn.disabled = false;
      }
    };

    canvas.addEventListener("mousedown", startDrawing);
    canvas.addEventListener("mouseup", stopDrawing);
    canvas.addEventListener("mousemove", draw);

    canvas.addEventListener("touchstart", (e) => {
      e.preventDefault();
      startDrawing(e.touches[0]);
    });
    canvas.addEventListener("touchmove", (e) => {
      e.preventDefault();
      draw(e.touches[0]);
    });
    canvas.addEventListener("touchend", stopDrawing);

    return () => {
      canvas.removeEventListener("mousedown", startDrawing);
      canvas.removeEventListener("mouseup", stopDrawing);
      canvas.removeEventListener("mousemove", draw);
      canvas.removeEventListener("touchstart", startDrawing);
      canvas.removeEventListener("touchmove", draw);
      canvas.removeEventListener("touchend", stopDrawing);
    };
  }, [hasDrawn]);

  return (
    <div id="signature" className="w-4/5 mx-auto">
      <div className="text-gray-800 text-base my-4 mx-auto max-w-[508px]">
        <p id="infoMandat" className="mb-4 congratsUser">
          <span className="text-3xl text-gray-800 leading-tight">
            <b>{t('signatureSection.congratulations')}</b>
          </span>
          <br />
          <br />
          {t('signatureSection.pleaseSign')}{" "}
          <a
            target="_blank"
            href={Mandat}
            className="text-indigo-600 hover:text-indigo-800 transition-colors duration-200 underline"
          >
            {t('signatureSection.mandate')}
          </a>{" "}
          {t('signatureSection.toHaveInsurance')}
          <br />
          <br />
          {t('signatureSection.thisService')}{" "}
          <span className="text-green-600">{t('signatureSection.freeOfCharge')}</span>.
          <br />
          <br />
          <a
            target="_blank"
            href={Mandat}
            className="text-indigo-600 hover:text-indigo-800 transition-colors duration-200 underline"
          >
            {t('signatureSection.viewContract')}
          </a>
        </p>
        <div className="mt-4 text-lg text-orange-600">
          {t('signatureSection.pleaseSignBelow')}
        </div>
      </div>

      <div className="max-w-[508px] mx-auto">
        <label id="errorSignature" style={{ color: "red", display: "none" }}>
          {t('signatureSection.error')}
        </label>
        <center>
          <canvas
            ref={canvasRef}
            id="signatureCanvas"
            className="border-2 border-black cursor-crosshair w-full h-48"
          ></canvas>
        </center>
        <div className="flex justify-center space-x-4 my-5">
          <button
            onClick={clearCanvas}
            className="bg-red-500 text-white py-2 px-4 rounded-md border border-red-600 hover:bg-red-600 transition-colors duration-200"
          >
            {t('signatureSection.clear')}
          </button>
          <button
            ref={saveBtnRef}
            onClick={handleSave}
            className="bg-emerald-500 text-white py-2 px-4 rounded-md hover:bg-emerald-600 transition-colors duration-200"
          >
            {t('signatureSection.next')}
          </button>
        </div>
      </div>
    </div>
  );
}
