import React, { useEffect, useRef } from "react";
import img1 from "../assets/img/whiteLogos/processed_image_0.png";
import img01 from "../assets/img/whiteLogos/processed_image_0_5.png";
import img2 from "../assets/img/whiteLogos/processed_image_1.png";
import img3 from "../assets/img/whiteLogos/processed_image_1_5.png";
import img4 from "../assets/img/whiteLogos/processed_image_3.png";
import img5 from "../assets/img/whiteLogos/processed_image_4.png";
import img6 from "../assets/img/whiteLogos/processed_image_5.png";
import img7 from "../assets/img/whiteLogos/processed_image_6.png";
import img8 from "../assets/img/whiteLogos/processed_image_7.png";
import img9 from "../assets/img/whiteLogos/processed_image_8.png";
import img10 from "../assets/img/whiteLogos/processed_image_9.png";
import img11 from "../assets/img/whiteLogos/new_processed_image_0.png";
import img12 from "../assets/img/whiteLogos/new_processed_image_1.png";
import img13 from "../assets/img/whiteLogos/new_processed_image_2.png";
import img14 from "../assets/img/whiteLogos/new_processed_image_3.png";
import img15 from "../assets/img/whiteLogos/new_processed_image_4.png";
import img16 from "../assets/img/whiteLogos/new_processed_image_5.png";
import img17 from "../assets/img/whiteLogos/new_processed_image_6.png";
import img18 from "../assets/img/whiteLogos/new_processed_image_7.png";
import img19 from "../assets/img/whiteLogos/new_processed_image_8.png";
import img20 from "../assets/img/whiteLogos/new_processed_image_9.png";
import img21 from "../assets/img/whiteLogos/additional_processed_image_0.png";
import img22 from "../assets/img/whiteLogos/additional_processed_image_1.png";
import img23 from "../assets/img/whiteLogos/additional_processed_image_2.png";
import img24 from "../assets/img/whiteLogos/additional_processed_image_3.png";
import img25 from "../assets/img/whiteLogos/additional_processed_image_4.png";
import img26 from "../assets/img/whiteLogos/additional_processed_image_5.png";
import img27 from "../assets/img/whiteLogos/additional_processed_image_6.png";
import img28 from "../assets/img/whiteLogos/additional_processed_image_7.png";
import img29 from "../assets/img/whiteLogos/additional_processed_image_8.png";
import img30 from "../assets/img/whiteLogos/additional_processed_image_9.png";

export default function Carousel() {
  const carouselRef = useRef(null);
  const innerCarouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    const innerCarousel = innerCarouselRef.current;
    let start = null;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const elapsed = timestamp - start;

      const scrollAmount = (elapsed / 1000) * 40; // 100 pixels per second
      carousel.scrollLeft = scrollAmount % innerCarousel.clientWidth;

      requestAnimationFrame(step);
    };

    requestAnimationFrame(step);
  }, []);

  const images = [
    img1,
    img01,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30];

    const duplicateImages = Array.from({length:10}, () => images).flat();

  return (
    <>
      <div
        ref={carouselRef}
        className="overflow-hidden h-32 bg-emerald-700 flex items-center"
        style={{ whiteSpace: "nowrap" }}
      >
        <div ref={innerCarouselRef} className="flex">
          {duplicateImages.map((img, index) => (
            <React.Fragment key={index}>
              <div className="flex-shrink-0 w-20 flex items-center justify-center mr-32">
                <img
                  src={img}
                  alt={`Image ${index + 1}`}
                  className="h-full w-full object-contain"
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      
    </>
  );
}
