import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaHeart, FaCar, FaHome, FaLifeRing } from 'react-icons/fa';
import CtaButton from './CtaButton';
import Carousel from './Carousel';
import OnlineCTA from './OnlineCTA';

export default function InsuranceServices() {
  const { t } = useTranslation();

  const services = [
    {
      name: t('insuranceServices.services.healthInsurance.name'),
      description: t('insuranceServices.services.healthInsurance.description'),
      icon: <FaHeart className="text-emerald-600" />,
    },
    {
      name: t('insuranceServices.services.vehicleInsurance.name'),
      description: t('insuranceServices.services.vehicleInsurance.description'),
      icon: <FaCar className="text-emerald-600" />,
    },
    {
      name: t('insuranceServices.services.lifeInsurance.name'),
      description: t('insuranceServices.services.lifeInsurance.description'),
      icon: <FaLifeRing className="text-emerald-600" />,
    },
    {
      name: t('insuranceServices.services.homeInsurance.name'),
      description: t('insuranceServices.services.homeInsurance.description'),
      icon: <FaHome className="text-emerald-600" />,
    },
  ];

  return (
    <div className="text-black py-10 max-w-7xl mx-auto">
      <h2 className="text-3xl font-bold mb-4">{t('insuranceServices.heading')}</h2>

      <p className="mb-8">{t('insuranceServices.introText')}</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {services.map((service, index) => (
          <div key={index} className="flex space-x-4 items-start p-4 border border-gray-200 rounded-lg shadow-sm">
            <div className="flex-shrink-1 mt-1">
              {service.icon}
            </div>
            <div>
              <h3 className="text-xl font-semibold">{service.name}</h3>
              <p className="mt-2 text-gray-700">{service.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-10 flex justify-center">
        <CtaButton text={t('insuranceServices.ctaButtonText')} url="#" />
      </div>

      <div className="text-black py-10 mx-auto">
        <h2 className="text-3xl font-bold mb-6">{t('insuranceServices.sectionTitle')}</h2>

        <p className="mb-6">{t('insuranceServices.subsections.whyMandatory.content')}</p>
        
        <h3 className="text-2xl font-semibold mb-4">{t('insuranceServices.subsections.basicVsSupplementary.title')}</h3>
        <p className="mb-6">{t('insuranceServices.subsections.basicVsSupplementary.content1')}</p>
        <p className="mb-6">{t('insuranceServices.subsections.basicVsSupplementary.content2')}</p>

        <div className="mb-6">
          <Carousel />
        </div>

        <h3 className="text-2xl font-semibold mb-4">{t('insuranceServices.subsections.premiumByLocation.title')}</h3>
        <p className="mb-6">{t('insuranceServices.subsections.premiumByLocation.content')}</p>

        <h3 className="text-2xl font-semibold mb-4">{t('insuranceServices.subsections.deductibleRole.title')}</h3>
        <p className="mb-6">{t('insuranceServices.subsections.deductibleRole.content')}</p>

        <h3 className="text-2xl font-semibold mb-4">{t('insuranceServices.subsections.insuranceModels.title')}</h3>
        <p className="mb-6">{t('insuranceServices.subsections.insuranceModels.content')}</p>
        <ul className="list-disc list-inside mb-6">
          <li><strong>{t('insuranceServices.subsections.insuranceModels.list.standard')}</strong></li>
          <li><strong>{t('insuranceServices.subsections.insuranceModels.list.hmo')}</strong></li>
          <li><strong>{t('insuranceServices.subsections.insuranceModels.list.gp')}</strong></li>
          <li><strong>{t('insuranceServices.subsections.insuranceModels.list.telmed')}</strong></li>
          <li><strong>{t('insuranceServices.subsections.insuranceModels.list.pharmed')}</strong></li>
        </ul>
        <p className="mb-6">{t('insuranceServices.subsections.insuranceModels.conclusion')}</p>

        <h3 className="text-2xl font-semibold mb-4">{t('insuranceServices.subsections.whySupplementary.title')}</h3>
        <p className="mb-6">{t('insuranceServices.subsections.whySupplementary.content')}</p>
        <ul className="list-disc list-inside mb-6">
          <li>{t('insuranceServices.subsections.whySupplementary.list.prescriptions')}</li>
          <li>{t('insuranceServices.subsections.whySupplementary.list.emergencyTransport')}</li>
          <li>{t('insuranceServices.subsections.whySupplementary.list.abroadCoverage')}</li>
        </ul>
        <p className="mb-6">{t('insuranceServices.subsections.whySupplementary.conclusion')}</p>

        <h3 className="text-2xl font-semibold mb-4">{t('insuranceServices.subsections.choosingTips.title')}</h3>
        <p className="mb-6">{t('insuranceServices.subsections.choosingTips.content')}</p>

        <h3 className="text-2xl font-semibold mb-4">{t('insuranceServices.subsections.premiumDiscounts.title')}</h3>
        <p className="mb-6">{t('insuranceServices.subsections.premiumDiscounts.content')}</p>
        <ul className="list-disc list-inside mb-6">
          <li>{t('insuranceServices.subsections.premiumDiscounts.list.familyDiscount')}</li>
          <li>{t('insuranceServices.subsections.premiumDiscounts.list.childrenDiscount')}</li>
          <li>{t('insuranceServices.subsections.premiumDiscounts.list.companyDiscount')}</li>
        </ul>

        <h3 className="text-2xl font-semibold mb-4">{t('insuranceServices.subsections.ctaConclusion.title')}</h3>
        <p>{t('insuranceServices.subsections.ctaConclusion.content')}</p>
        <p className="mt-6">{t('insuranceServices.subsections.ctaConclusion.closing')}</p>
      </div>
      
      <OnlineCTA />
    </div>
  );
}
