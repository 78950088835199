import React from 'react';
import { useTranslation } from 'react-i18next';
import Mobile from '../assets/img/mobile-background.webp';
import CtaButton from './CtaButton';

export default function MobileHero() {
    const { t } = useTranslation();

    return (
        <div
            className="h-screen w-full bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${Mobile})` }}
        >
            <div className="mx-auto max-w-lg backdrop-blur-md px-5 py-12 rounded-lg overflow-hidden">
                <h1 className="text-3xl sm:text-4xl font-bold tracking-tight text-white text-center">
                    {t('mobileHero.heading')}
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-300 text-center">
                    {t('mobileHero.subheading')}
                </p>
                <div className="mt-10 flex flex-col sm:flex-row justify-center items-center gap-x-6">
                    <CtaButton text={t('mobileHero.ctaButton')} url="" />
                    <a
                        href="/insurance"
                        className="mt-4 sm:mt-0 text-sm font-semibold leading-6 text-white"
                    >
                        {t('mobileHero.learnMore')} <span aria-hidden="true">→</span>
                    </a>
                </div>
            </div>
        </div>
    );
}
