import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files here
import enTranslation from './locales/en/translation.json';
import deTranslation from './locales/de/translation.json';
import csTranslation from './locales/cs/translation.json';

const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      de: {
        translation: deTranslation
      },
        cs: {
            translation: csTranslation
        }
    },
    lng: savedLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
