import React, { useEffect } from 'react';

const CalendlyWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div 
      className="calendly-inline-widget" 
      data-url="https://calendly.com/robert-kolar-langpartner/kostenloses-erstgesprach-zur-rundumbetreuung-buchen?hide_gdpr_banner=1&primary_color=10b981"
      style={{ minWidth: '320px', height: '700px' }}
    />
  );
};

export default CalendlyWidget;