import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../assets/img/logo.png";
import CtaButton from "./CtaButton";
import SelectTranslation from "./SelectTranslation";

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation(); // Get the current location
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);

    if (location.pathname === "/" && windowWidth > 768) {
      const handleScroll = () => {
        const heroSection = document.querySelector(".hero-section");
        if (heroSection) {
          const heroHeight = heroSection.offsetHeight;
          const scrollPosition = window.scrollY;

          if (scrollPosition > heroHeight) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      // If not on the homepage, set scrolled to true so that the navbar has the emerald background
      setScrolled(true);
    }
  }, [location.pathname]); // Re-run the effect when the pathname changes

  return (
    <div
      className={`${
        scrolled ? "bg-emerald-700" : "bg-transparent"
      } fixed top-0 z-50 w-full transition-colors duration-300`}
    >
      <nav className="p-4">
        <ul className="flex items-center justify-between max-w-7xl mx-auto">
          {/* Logo in the center */}
          <li className="flex-grow text-center">
            <a href="/">
              <img src={logo} alt="Lang & Partner" className="h-8" />
            </a>
          </li>

          {/* Navigation links visible on larger screens */}
          <div className="hidden sm:flex items-center space-x-6 mr-8">
            <li>
              <a href="/insurance" className="text-white hover:text-gray-900">
                {t('navbar.insurance')}
              </a>
            </li>
            <li>
              <a href="/mandate" className="text-white hover:text-gray-900">
                {t('navbar.mandate')}
              </a>
            </li>
            <li>
              <a href="/newsroom" className="text-white hover:text-gray-900">
                {t('navbar.newsroom')}
              </a>
            </li>
            <li>
            <SelectTranslation />
            </li>
          </div>
          {/* CTA button on the right side */}
          <li className="hidden sm:block">
            <CtaButton text={t('navbar.ctaButton')} url={""} />
          </li>

          {/* Hamburger menu - only visible on small screens */}
          <li className="sm:hidden flex items-center">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-white focus:outline-none"
            >
              <div className="bar bg-white h-1 w-6 mb-1"></div>
              <div className="bar bg-white h-1 w-6 mb-1"></div>
              <div className="bar bg-white h-1 w-6"></div>
            </button>
          </li>
        </ul>

        {/* Dropdown menu - visible only when hamburger is clicked */}
        {menuOpen && (
          <div className="sm:hidden text-white">
            <ul className="flex flex-col space-y-4 py-4">
              <li>
                <a href="/insurance" className="hover:text-gray-900">
                  {t('navbar.insurance')}
                </a>
              </li>
              <li>
                <a href="/mandate" className="hover:text-gray-900">
                  {t('navbar.mandate')}
                </a>
              </li>
              <li>
                <a href="/newsroom" className="hover:text-gray-900">
                  {t('navbar.newsroom')}
                </a>
              </li>
              <li>
                <CtaButton text={t('navbar.ctaButton')} url={""} />
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
}
