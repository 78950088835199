import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { IoStar } from "react-icons/io5";
import { FaPen } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Reviews = () => {
  const { t } = useTranslation();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch('https://wwtivedhh5.execute-api.eu-central-1.amazonaws.com/prd/review');
        if (!response.ok) {
          throw new Error('Failed to fetch reviews');
        }
        const data = await response.json();
        const reviews = JSON.parse(data.body).Reviews;
        setReviews(reviews);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) {
    return <div className="text-center text-emerald-500">Loading reviews...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">Error: {error}</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const handleWriteReview = () => {
    // Handle the action here, such as opening a modal or redirecting to a review page.
    // For example, you might redirect to a Google review page:
    window.open('https://maps.app.goo.gl/vskAHuNd9fyGoXR68', '_blank');
  };

  return (
    <div >
<div className="max-w-4xl mx-auto p-4 mt-8 mb-12">
      <h2 className="text-3xl font-bold text-center mb-6">{t('review.title')}</h2>
      
      {/* Write Review Button */}
      <div className="text-center mb-6 ">
        <button
          onClick={handleWriteReview}
          className="bg-emerald-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-emerald-700 transition-colors flex gap-1.5 items-center mx-auto"
        >
          <FaPen />{t('review.writeReview')}
        </button>
      </div>

      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="bg-slate-50 p-6 rounded-lg mr-2">
            <div className="flex items-center mb-4">
              <img
                src={review.profile_photo_url || 'https://via.placeholder.com/40'}
                alt={review.author_name}
                className="w-10 h-10 rounded-full mr-4"
              />
              <div>
                <h4 className="font-semibold">{review.author_name}</h4>
                <div className="flex items-center justify-center">
                  <div className="flex items-center justify-center">
                    {Array.from({ length: 5 }).map((_, starIndex) => (
                      <svg
                        key={starIndex}
                        className={`w-5 h-5 ${
                          starIndex < review.rating ? 'text-yellow-500' : 'text-gray-300'
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <IoStar />
                      </svg>
                    ))}
                  </div>
                  <p className="text-sm text-gray-500 ml-2">
                    {new Date(review.time * 1000).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-gray-700">{review.text}</p>
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

// Custom arrow components
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} next-arrow`}
      style={{ ...style, display: 'block', right: '10px' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} prev-arrow`}
      style={{ ...style, display: 'block', left: '10px' }}
      onClick={onClick}
    />
  );
};

export default Reviews;
