import "../App.css";
import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "../components/navbar";
import Hero from "../components/Hero";
import { Modal, ModalProvider } from "../components/ModalComponents";
import Products from "../components/Products";
import MainContent from "../components/MainContent";
import Carousel from "../components/Carousel";
import Team from "../components/Team";
import Footer from "../components/Footer";
import MobileHero from "../components/MobileHero";
import familypic from "../assets/img/family-forest.jpg";
import CalendlyWidget from "../components/CalendlyWidget";
import Reviews from "../components/Reviews";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check the window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width as per your breakpoint for mobile
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ModalProvider>
      <HelmetProvider>
        <Helmet>
          <title>
            Willkommen bei Lang + Partner - Ihre Versicherungsberater
          </title>
          <meta
            name="description"
            content="Lang + Partner bietet massgeschneiderte Versicherungsdienstleistungen für Expats in der Schweiz. Erhalten Sie fachkundige Beratung zu Versicherungen!"
          />
          <meta
            property="og:title"
            content="Willkommen bei Lang + Partner - Ihre Finanzberatung"
          />
          <meta
            property="og:description"
            content="Lang + Partner bietet vertrauensvolle und nachhaltige Versicherungslösungen für Expats und alle Lebensphasen."
          />
          <meta property="og:image" content={familypic} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:title"
            content="Willkommen bei Lang + Partner - Ihre Finanzberatung"
          />
          <meta
            property="twitter:description"
            content="Lang + Partner bietet vertrauensvolle und nachhaltige Versicherungslösungen für Expats und alle Lebensphasen."
          />
          <meta property="twitter:image" content={familypic} />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      </HelmetProvider>

      <div className="App">
        <header className="App-header">
          <Navbar isMobile={isMobile} />
          {isMobile ? <MobileHero /> : <Hero />}
          <Products />
          <MainContent />
          <Carousel />
          <Team />
          <Reviews />
          <CalendlyWidget />
          <Footer />
        </header>
      </div>
    </ModalProvider>
  );
}

export default App;
