import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import { Modal, ModalProvider } from "../components/ModalComponents";
import PrivacyPolicy from "../components/PrivacyPolicy";
import familypic from "../assets/img/family-forest.jpg"; // Importing the family picture

export default function TOS() {
  return (
    <ModalProvider>
      <HelmetProvider>
        <Helmet>
          <title>Terms of Service - Lang + Partner</title>
          <meta
            name="description"
            content="Erfahren Sie mehr über die Allgemeinen Geschäftsbedingungen von Lang + Partner."
          />
          <meta
            property="og:title"
            content="Terms of Service - Lang + Partner"
          />
          <meta
            property="og:description"
            content="Erfahren Sie mehr über die Allgemeinen Geschäftsbedingungen von Lang + Partner."
          />
          <meta property="og:image" content={familypic} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:title"
            content="Terms of Service - Lang + Partner"
          />
          <meta
            property="twitter:description"
            content="Erfahren Sie mehr über die Allgemeinen Geschäftsbedingungen von Lang + Partner."
          />
          <meta property="twitter:image" content={familypic} />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      </HelmetProvider>

      <div>
        <Navbar />
        <PrivacyPolicy />
        <Footer />
      </div>
    </ModalProvider>
  );
}
