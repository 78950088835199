import "../App.css";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "../components/navbar";
import { Modal, ModalProvider } from "../components/ModalComponents";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Banner from "../components/Banner";
import Lake from "../assets/img/lake.jpg";
import InsuranceServices from "../components/InsuranceServices";
import familypic from "../assets/img/family-forest.jpg"; // Importing the family picture

function App() {
  return (
    <ModalProvider>
      <HelmetProvider>
        <Helmet>
          <title>Versicherungsdienste - Lang + Partner</title>
          <meta
            name="description"
            content="Lang + Partner bietet massgeschneiderte Versicherungsdienstleistungen für Expats in der Schweiz. Erhalten Sie fachkundige Beratung zu Versicherungen!"
          />
          <meta
            property="og:title"
            content="Versicherungsdienste - Lang + Partner"
          />
          <meta
            property="og:description"
            content="Lang + Partner bietet massgeschneiderte Versicherungsdienstleistungen für Expats in der Schweiz. Erhalten Sie fachkundige Beratung zu Versicherungen!"
          />
          <meta property="og:image" content={familypic} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:title"
            content="Versicherungsdienste - Lang + Partner"
          />
          <meta
            property="twitter:description"
            content="Lang + Partner bietet massgeschneiderte Versicherungsdienstleistungen für Expats in der Schweiz. Erhalten Sie fachkundige Beratung zu Versicherungen!"
          />
          <meta property="twitter:image" content={familypic} />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      </HelmetProvider>

      <div className="App">
        <header className="App-header">
          <Navbar />
          <div className="mt-16">
            <Banner image={Lake} />
            <div className="flex flex-col md:flex-row max-w-7xl mx-auto">
              {/* Insurance Services: Left Section */}
              <div className="md:w-3/4 p-8">
                <InsuranceServices />
              </div>

              {/* Form: Small, Sticky Right Section */}
              <div className="md:w-1/4 md:sticky md:top-0 p-4 bg-white">
                <Form />
              </div>
            </div>
          </div>

          <Footer />
        </header>
      </div>
    </ModalProvider>
  );
}

export default App;
