import React from 'react';
import { useTranslation } from 'react-i18next';
import { GrUserExpert } from "react-icons/gr";
import { FaHandsHelping } from "react-icons/fa";
import { IoIosTimer } from "react-icons/io";
import { AiOutlineProduct } from "react-icons/ai";

const features = [
  {
    nameKey: 'feature.feature1Name',
    descriptionKey: 'feature.feature1Description',
    icon: GrUserExpert,
  },
  {
    nameKey: 'feature.feature2Name',
    descriptionKey: 'feature.feature2Description',
    icon: AiOutlineProduct,
  },
  {
    nameKey: 'feature.feature3Name',
    descriptionKey: 'feature.feature3Description',
    icon: FaHandsHelping,
  },
  {
    nameKey: 'feature.feature4Name',
    descriptionKey: 'feature.feature4Description',
    icon: IoIosTimer,
  },
]

export default function Feature() {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-2xl px-6 sm:px-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">
          {t('feature.heading')}
        </h2>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.nameKey}>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                    <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                  </div>
                  {t(feature.nameKey)}
                </dt>
                <dd className="mt-1 text-base leading-7 text-gray-600">{t(feature.descriptionKey)}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
