import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="bg-white text-gray-900 px-6 py-16 max-w-4xl mx-auto mt-12">
      <h1 className="text-3xl font-bold mb-6">{t('privacy.heading')}</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t('privacy.introduction.heading')}</h2>
        <p className="text-lg leading-7">{t('privacy.introduction.content')}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t('privacy.dataCollection.heading')}</h2>
        <p className="text-lg leading-7">{t('privacy.dataCollection.content')}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t('privacy.dataStorage.heading')}</h2>
        <p className="text-lg leading-7">{t('privacy.dataStorage.content')}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t('privacy.apiCalls.heading')}</h2>
        <p className="text-lg leading-7">{t('privacy.apiCalls.content')}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t('privacy.googleTracking.heading')}</h2>
        <p className="text-lg leading-7">{t('privacy.googleTracking.content')}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t('privacy.thirdParties.heading')}</h2>
        <p className="text-lg leading-7">{t('privacy.thirdParties.content')}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t('privacy.changes.heading')}</h2>
        <p className="text-lg leading-7">{t('privacy.changes.content')}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t('privacy.contact.heading')}</h2>
        <p className="text-lg leading-7">{t('privacy.contact.content')}</p>
      </section>
    </div>
  );
}
