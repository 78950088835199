export default function Input({ id, name, type, placeholder, value, onChange, label }) {
  return (
    <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-emerald-600">
      <label htmlFor={name} className="block text-xs font-medium text-gray-900">
        {label}
      </label>
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value} // Controlled value
        onChange={onChange} // Handling change events
        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
      />
    </div>
  );
}
